import { service } from '.';

export default {
  getAll: (query) => service.get(`/driver/list?${query}`),
  getOne: (id) => service.get(`/driver/${id}`),
  getContext: () => service.get(`/driver/context`),
  create: (data) => service.post('/driver/new', data),
  update: (data) => service.put(`/driver/edit`, data),
  editStatus: (id, status) =>
    service.put(`/driver/edit-status/${id}/${status}`),
  updateMobileAppAccess: (data) =>
    service.post(`/driver/update/mobile_app_access`, data),
  delete: (id) => service.delete(`/driver/${id}`),
  sendFromLink: (data) => service.post('/driver/send_form_link', data),
  createForm: (company, data) =>
    service.post(`/driver/form_in_process/${company}`, data),
  deleteLicenseFile: (driverId, fileId) =>
    service.delete(`/driver/delete_license_file/${driverId}/${fileId}`),
};
