import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, Input, Button } from 'reactstrap';
import * as Icon from 'react-feather';
import { Helmet } from 'react-helmet';
import { TextField, Box, IconButton } from '@mui/material';
import { toast, Flip } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { AnimatePresence } from 'framer-motion';
import FilterListIcon from '@mui/icons-material/FilterList';
import SweetAlert from '../../../../components/main/SweetAlert';
import AnimationDiv from '../../../../components/main/Animations/Transition';
import FilterTools from '../../../../components/main/FilteredBody/Drivers';
import stateProvinceService from '../../../../services/state_province';
import driverService from '../../../../services/drivers';
import { fetchData } from '../../../../redux/modules/drivers/actions';
import { useQuery } from '../../useQuery';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { driversHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTipsUser, toolTipsAdmin } from './helper';
import { useStyles } from './style';
import FormLinkModal from '../AddForm/SendFormModal';
import NotificationModal from '../Notifications';
import ModalWindow from '../../../../components/main/Modals';
import ModalWindowFullScreen from '../../../../components/main/Modals/FullScreen';

export default function Drivers() {
  const {
    query,
    deleted,
    inProcess,
    setSearch,
    setDeleted,
    setInProcess,
    setRefresh,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const classes = useStyles();
  const navigate = useNavigate();
  const [isFilterShow, setFilterIsShow] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [page, setPage] = useState(null);
  // const [total, setTotal] = useState(1);
  const [states, setStates] = useState([]);
  const [deletingId, setDeletingId] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [driverStatuses, setDriverStatuses] = useState([]);

  const [modal, setModal] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [showModalNotification, setShowModalNotification] = useState(false);

  const headers = useMemo(() => headerMaker(driversHeader), [driversHeader]);

  const { data, loading, total } = useSelector((state) => state.driversReducer);

  localStorage.setItem('total_data', total);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));
    // console.log(cData);

    // setColumns(cData);

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));
  // console.log(decodedToken);

  // state = {
  //   paymentTypes: [],
  //   page: null,
  //   total: 1,
  //   loading: false,
  //   states: [],
  //   deleteAlert: false,
  //   driverStatuses: [],
  //   data: [],
  //   paginationPageSize: 100000,
  //   defaultColDef: {
  //     sortable: true,
  //     resizable: true,
  //     floatingFilter: true,
  //     // suppressMenu: true,
  //     // wrapText: true,
  //     autoHeight: true,
  //     tooltipValueGetter: (params) => {
  //       return params.value;
  //     },
  //   },
  //   columnDefs: [
  //     {
  //       headerName: '#',
  //       field: 'index',
  //       minWidth: 80,
  //       flex: 1,
  //       filter: false,
  //       cellStyle: function (params) {
  //         return {
  //           fontWeight: '500',
  //         };
  //       },
  //     },
  //     {
  //       headerName: 'Name',
  //       field: 'name',
  //       minWidth: 200,
  //       flex: 1,
  //       filter: 'agTextColumnFilter',
  //       cellRendererFramework: function (params) {
  //         return (
  //           <Link
  //             className="link-in-table"
  //             to={`/driver/view/${params.data.id}`}
  //           >
  //             {params.value}
  //           </Link>
  //         );
  //       },
  //       cellStyle: { 'white-space': 'normal' },
  //     },
  //     {
  //       headerName: 'Address',
  //       field: 'address',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 130,
  //       resizable: true,
  //       flex: 1,
  //       tooltip: (params) => {
  //         return params.value;
  //       },
  //     },
  //     {
  //       headerName: 'PrimaryPhone',
  //       field: 'primaryPhone',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 130,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Alternate phone',
  //       field: 'alternatePhone',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 130,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Payment Type',
  //       field: 'paymentType',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 100,
  //       cellStyle: { 'white-space': 'normal' },
  //     },
  //     {
  //       headerName: 'Status',
  //       field: 'status',
  //       filter: false,
  //       minWidth: 120,
  //       // flex:1,
  //       cellStyle: function (params) {
  //         return {
  //           fontSize: '13px',
  //           color: params.data.driverStatusColor ? 'white' : 'black',
  //           backgroundColor: params.data.driverStatusColor
  //             ? params.data.driverStatusColor
  //             : 'white',
  //           textTransform: 'uppercase',
  //           textAlign: 'center',
  //         };
  //       },
  //       cellRendererFramework: (params) => {
  //         let el = this.state.driverStatuses.find(
  //           (item) => item.id === params.value
  //         );
  //         return <div>{el && el.name}</div>;
  //       },
  //     },
  //     {
  //       headerName: 'Hired on',
  //       field: 'hiredOn',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 150,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'License exp',
  //       field: 'licenseExp',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 150,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Medical Card Exp',
  //       field: 'medicalCardExp',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 150,
  //       flex: 1,
  //     },
  //     {
  //       minWidth: 150,
  //       flex: 1,
  //       headerName: 'Actions',
  //       field: 'actions',
  //       sortable: false,
  //       editable: false,
  //       suppressMenu: false,
  //       cellRendererFramework: (params) => {
  //         return (
  //           <div>
  //             <Link to={`/driver/view/${params.data.id}`}>
  //               <Icon.Eye className="icon-button" size={20} color="darkgray" />
  //             </Link>
  //             {this.props.userRole !== 'dispatcher' &&
  //               this.props.userRole !== 'updater' && (
  //                 <Link to={`/driver/edit/${params.data.id}`}>
  //                   <Icon.Edit
  //                     onClick={() => history.push()}
  //                     className="icon-button ml-1"
  //                     size={20}
  //                     color="darkgray"
  //                   />
  //                 </Link>
  //               )}
  //             {this.props.userRole !== 'dispatcher' &&
  //               this.props.userRole !== 'updater' && (
  //                 <Icon.Delete
  //                   onClick={() => this.nominateToDelete(params.data.id)}
  //                   className="icon-button ml-1"
  //                   size={20}
  //                   color="darkgray"
  //                 />
  //               )}
  //           </div>
  //         );
  //       },
  //     },
  //   ],
  // };

  const nominateToDelete = (id) => {
    setDeleteAlert(true);
    setDeletingId(id);
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.page !== prevState.page) {
  //     this.setState({
  //       loading: true,
  //     });
  //     this.updateInfo(this.state.page);
  //   }
  // }

  const updateInfo = (pageNumber) => {
    const name =
      document.getElementById('name') && document.getElementById('name').value;
    const phone =
      document.getElementById('phone') &&
      document.getElementById('phone').value;
    // setLoading(true);

    driverService
      .getAll(
        `/driver/list?sort=id,DESC&size=100&page=${pageNumber}${
          name && `&searchNameText=${name}`
        }${phone && `&phone=${phone}`}`
      )
      .then((data) => {
        if (
          document.getElementById('number') &&
          document.getElementById('phone')
        ) {
          if (
            document.getElementById('name').value !== name ||
            document.getElementById('phone').value !== phone
          ) {
            return;
          }
        }

        const dataToShow = [];
        data.content.forEach((el, i) => {
          const paymentTypeId = paymentTypes.filter((obj) => {
            return obj.id === el.defaultPaymentTypeId;
          });
          const stateId = states.filter((obj) => {
            return obj.id === el.stateProvinceId;
          });
          const elToShow = {
            index: i + 1 + page * 100,
            id: el.id,
            name: el.lastName + ' ' + el.firstName,
            address: stateId[0] && stateId[0].name,
            primaryPhone: el.phone,
            alternatePhone: el.alternatePhone,
            paymentType: paymentTypeId.length > 0 && paymentTypeId[0].name,
            status: el.driverStatusId,
            hiredOn: el.hireDateFormatted,
            licenseExp: el.licenseExpirationFormatted,
            medicalCardExp: el.medicalCardRenewalFormatted,
            driverStatusColor: el.driverStatusColor,
          };
          dataToShow.push(elToShow);
        });
        // setData(dataToShow);
        // setLoading(false);
        // setTotal(data.totalElements);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const deleteDriver = () => {
    driverService
      .delete(`${deletingId}`)
      .then((res) => {
        setRefresh(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    setDeletingId(null);
    setDeleteAlert(false);
  };

  const componentDidMount = () => {
    // setLoading(true);

    stateProvinceService
      .getAll()
      .then((data) => setStates(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    driverService
      .getContext()
      .then((data) => {
        setPaymentTypes(data.payment_types);
        setDriverStatuses(data.driver_statuses);
        // updateInfo(0);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const doubleClick = (id) => {
    navigate(`/drivers/view/${id}`);
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Helmet>
        <title> Drivers </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh)',
          py: 1,
        }}
      >
        <ModalWindow
          title="Form link"
          modal={showModal}
          handleModal={toggleModal}
          maxWidth="md"
          body={
            <FormLinkModal
              modal={showModal}
              tenant={localStorage.getItem('current_account').toLowerCase()}
              toggle={toggleModal}
            />
          }
        />
        <ModalWindowFullScreen
          title="Notifications"
          modal={showModalNotification}
          handleModal={() => {
            setShowModalNotification(false);
          }}
          body={
            <NotificationModal
              modal={showModalNotification}
              toggle={() => {
                setShowModalNotification(false);
              }}
            />
          }
        />
        <Loading loading={loading} />
        <SweetAlert
          title="Are you sure ?"
          warning
          show={deleteAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => {
            deleteDriver(deletingId);
          }}
          onCancel={() => setDeleteAlert(false)}
        />

        <Card
          className="overflow-hidden agGrid-card"
          style={{ marginRight: 8 }}
        >
          <div className="d-flex justify-content-between align-items-center mt-2 mx-2 mb-1">
            <h3 className="mb-0">All drivers list</h3>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mr-1">
                <Button
                  size="sm"
                  color="success"
                  className="d-flex align-items-center"
                  type="button"
                  onClick={() => {
                    setShowModalNotification(true);
                  }}
                >
                  <Icon.AlertOctagon size={20} /> Notifications
                </Button>
              </div>
              <div className="mr-1">
                <Link
                  to="/drivers/new"
                  className="d-flex align-items-center text-white"
                >
                  <Button
                    size="sm"
                    color="success"
                    className="d-flex align-items-center"
                    type="button"
                  >
                    <Icon.Plus size={20} /> Add new driver
                  </Button>
                </Link>
              </div>
              <div>
                <Button
                  size="sm"
                  color="success"
                  className="d-flex align-items-center"
                  type="button"
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  <Icon.Send size={20} /> Send form link
                </Button>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mx-2 mb-1">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton
              color="primary"
              aria-label="add"
              onClick={() => {
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton>
          </div>
          <div className={isFilterShow ? 'align-items-center mb-2 mx-2' : ''}>
            <AnimatePresence className={classes.filters}>
              {isFilterShow ? (
                <AnimationDiv>
                  <FilterTools
                    deleted={deleted}
                    inProcess={inProcess}
                    setDeleted={setDeleted}
                    setInProcess={setInProcess}
                  />
                </AnimationDiv>
              ) : null}
            </AnimatePresence>
          </div>
          <CardBody className="py-0 no-pagination">
            <div className="w-100 ag-grid-table with-pagination">
              <Table
                data={data}
                headers={headers}
                toolTips={
                  decodedToken.role === 'ROLE_ADMIN' ||
                  decodedToken.role === 'SUPER_ADMIN'
                    ? toolTipsAdmin
                    : toolTipsUser
                }
                onChange={handleOnTableChange}
                modal={handleModal}
                deleteModal={nominateToDelete}
                doubleClick={doubleClick}
                columns={columns}
                setColumns={setColumns}
                // hidePagination={1}
              />
            </div>
          </CardBody>
        </Card>
      </Box>
    </>
  );
}
