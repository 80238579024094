/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  // Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  // CardHeader,
  Input,
} from 'reactstrap';
import {
  Box,
  Typography,
  Divider,
  Avatar,
  Grid,
  Paper,
  Card,
  // CardBody,
  CardHeader,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { toast, Flip } from 'react-toastify';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { styled } from '@mui/material/styles';
import SweetAlert from '../../../components/main/SweetAlert';
import NewAccounting from './Modals/NewAccounting';
import RenameFile from './Modals/RenameFile';
import DataTable from './Table/DataTable';
import PdfViewer from './Modals/PdfViewer';
import FilesMenu from './Menu/FilesMenu';
import ModalWindow from '../../../components/main/Modals';
import ownedCompanyService from '../../../services/owned_company';
import accountingService from '../../../services/accounting';
import { fetchData } from '../../../redux/modules/accounting_files/actions';
import { useQuery } from '../useQuery';
import './style.css';

const columns = [
  { id: 'fileId', label: '', maxWidth: 0 },
  { id: 'number', label: '№', minWidth: 70 },
  {
    id: 'fileName',
    label: 'File name',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'time',
    label: 'Time',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'companyName',
    label: 'Company name',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'createdTime',
    label: 'Created time',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'updatedTime',
    label: 'Updated time',
    minWidth: 170,
    align: 'right',
  },
  {
    id: '',
    label: 'Actions',
    minWidth: 100,
    align: 'right',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Accounting() {
  const {
    query,
    search,
    status,
    start,
    end,
    pageIndex,
    setSearch,
    setStatus,
    setStart,
    setEnd,
    setCurrentEmployerId,
    setDispatcherId,
    setPageIndex,
    setPageSize,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [companyId, setCompanyId] = useState();
  const [companies, setCompanies] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [savedFiles, setSavedFiles] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showNewAccountingModal, setShowNewAccountingModal] = useState(false);
  const [showPdfViewerModal, setShowPdfViewerModal] = useState(false);
  const [fileId, setFileId] = useState();
  const [days, setDays] = useState([]);
  const [saveAlert, setSaveAlert] = useState(false);
  const [sendAlert, setSendAlert] = useState(false);
  const [sendData, setSendData] = useState([]);
  const [currentFileName, setCurrentFileName] = useState('');
  const [openDrop, setOpenDrop] = useState(false);
  const [pdfData, setPdfData] = useState([]);

  const { data, total, error } = useSelector(
    (state) => state.accountingFilesReducer
  );

  const getCompanies = () => {
    ownedCompanyService
      .getAllVisible('sort=id,DESC&size=10000')
      .then((data) => {
        const companies = data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setCompanies(companies);
      });
  };

  const getDateString = (date) => {
    return (
      (new Date(date).getMonth() + 1 < 10
        ? '0' + parseInt(new Date(date).getMonth() + 1, 10)
        : parseInt(new Date(date).getMonth() + 1, 10)) +
      '/' +
      (new Date(date).getDate() < 10
        ? '0' + new Date(date).getDate()
        : new Date(date).getDate()) +
      '/' +
      new Date(date).getFullYear() +
      ' ' +
      (new Date(date).getHours() < 10
        ? '0' + new Date(date).getHours()
        : new Date(date).getHours()) +
      ':' +
      (new Date(date).getMinutes() < 10
        ? '0' + new Date(date).getMinutes()
        : new Date(date).getMinutes())
    );
  };

  const getDayString = (date) => {
    return (
      (new Date(date).getMonth() + 1 < 10
        ? '0' + parseInt(new Date(date).getMonth() + 1, 10)
        : parseInt(new Date(date).getMonth() + 1, 10)) +
      '/' +
      (new Date(date).getDate() < 10
        ? '0' + new Date(date).getDate()
        : new Date(date).getDate()) +
      '/' +
      new Date(date).getFullYear()
    );
  };

  const getAccountingFiles = () => {
    setLoadingFiles(true);
    accountingService
      .getAccountingFiles(
        `${`search=${document.querySelector('#search').value}`}${
          startDate ? `&startTime=${startDate}` : ''
        }${endDate ? `&endTime=${endDate}` : ''}${
          companyId ? `&companyId=${companyId}` : ''
        }`
      )
      .then((data) => {
        const arr = [];
        let a = 1;
        data.data.forEach((element) => {
          arr.push({
            fileId: element.id,
            number: a,
            companyName: element.companyName,
            fileName: element.fileName,
            time:
              getDateString(element.startTime) +
              ' - ' +
              getDateString(element.endTime),
            createdTime: getDateString(element.createdTime),
            updatedTime: getDateString(element.updatedTime),
          });
          a += 1;
        });
        setSavedFiles(arr);
        setLoadingFiles(false);
        // this.setState({ data, loading: false });
      })
      .catch((err) => {
        setLoadingFiles(false);
        console.log(err);
      });
  };

  const toggleNewAccounting = () => {
    setShowNewAccountingModal(!showNewAccountingModal);
  };

  const getOneAccountingFileData = (id) => {
    setLoading(true);
    accountingService
      .getOneAccountingFile(id)
      .then((data) => {
        const arr = [];
        let a = 0;
        const arrDay = [];
        arrDay.push(getDayString(data.data.startTime));
        const date = new Date(data.data.startTime);
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        setDays(arrDay);

        data.data.data.forEach((element) => {
          arr.push({
            rowId: a,
            number: a + 1,
            companyId: data.data.companyId,
            name: element.name,
            email: element.email,
            address: element.address,
            phone: element.phone,
            unit: element.unit,
            gross: typeof element.gross !== 'number' ? 0 : element.gross,
            dispatcher: element.dispatcher,
            collected_deposit: element.collected_deposit,
            rate: element.rate,
            fuel_discount: element.fuel_discount,
            efs_fuel: element.efs_fuel,
            efs_fleet: element.efs_fleet,
            toll: element.toll,
            miles: element.miles,
            days_0: element.days_0,
            days_1: element.days_1,
            days_2: element.days_2,
            days_3: element.days_3,
            days_4: element.days_4,
            days_5: element.days_5,
            days_6: element.days_6,
            total_day:
              element.days_0 * 1 +
              element.days_1 * 1 +
              element.days_2 * 1 +
              element.days_3 * 1 +
              element.days_4 * 1 +
              element.days_5 * 1 +
              element.days_6 * 1,
            cash_adv: element.cash_adv,
            expenses: element.expenses,
            bonus: element.bonus,
            violation_damage: element.violation_damage,
            total_pay: element.total_pay,
            note: element.note,
            dpm: element.dpm,
            previous_gross: element.previous_gross,
            cash_adv_note: element.cash_adv_note,
            exsp_note: element.exsp_note,
            bonus_note: element.bonus_note,
            violation_damage_note: element.violation_damage_note,
            paid_by_company: element.paid_by_company,
            salary: element.salary,
            fuel_efs_toll: element.fuel_efs_toll,
            dispatch_28: element.dispatch_28,
            insurance: element.insurance,
            lease: element.lease,
            other_exp: element.other_exp,
            profit_loss: element.profit_loss,
          });
          a += 1;
        });

        setRowData(arr);
        setLoading(false);
        let efsFuelN = 0;
        let fuelDiscountC = 0;
        let sumEfsFleetC = 0;
        let sumTollC = 0;
        let sumTotalPayC = 0;
        let sumGrossC = 0;
        let sumProfitLossC = 0;

        const newArr = arr.filter(
          (v, i, a) =>
            a.findIndex((v2) => ['unit'].every((k) => v2[k] === v[k])) === i
        );

        newArr.forEach((item) => {
          efsFuelN += item.efs_fuel * 1;
          fuelDiscountC += item.fuel_discount * 1;
          sumEfsFleetC += item.efs_fleet * 1;
          sumTollC += item.toll * 1;
          sumTotalPayC += item.totalPay * 1;
          sumGrossC += item.gross * 1;
          sumProfitLossC +=
            item.gross * 1 -
            item.salary * 1 -
            item.fuel_efs_toll * 1 -
            item.dispatch_28 * 1 -
            item.insurance * 1 -
            item.lease * 1 -
            item.other_exp * 1;
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getDownloadFile = (id) => {
    accountingService
      .getDownloadAccountingFile(id)
      .then((result) => {
        console.log(result);
        if (result.status === 'success') {
          window.open(result.data, '_blank');
        } else {
          toast.error(result.msg, {
            transition: Flip,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const updateDataFirst = (id, datas) => {
    const data = {
      id,
      data: datas,
    };
    accountingService
      .updateData(data)
      .then((res) => {
        getOneAccountingFileData(id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNewAccountingList = (startTime, endTime, comId, fileName) => {
    setLoading(true);
    accountingService
      .getAllList(
        `${`startTime=${startTime}`}${`&endTime=${endTime}`}${`&companyId=${comId}`}${`&fileName=${fileName}`}`
      )
      .then((data) => {
        getAccountingFiles();
        const arr = [];
        const a = 0;
        const arrDay = [];
        setFileId(data.fileId);
        arrDay.push(getDayString(data.startTime));
        const date = new Date(data.startTime);
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        arrDay.push(getDayString(date.setDate(date.getDate() + 1)));
        setDays(arrDay);
        data.data.forEach((element) => {
          if (element.number && element.number !== '') {
            if (element.driverOne !== null) {
              arr.push({
                rowId: a,
                number: a + 1,
                name:
                  element.driverOne.firstName +
                  ' ' +
                  element.driverOne.lastName,
                companyId: data.companyId,
                email: element.driverOne.email,
                address: element.driverOne.city,
                phone: element.driverOne.phone,
                unit: element.number.split('-')[0],
                gross: typeof element.calc !== 'number' ? 0 : element.calc,
                dispatcher: '',
                collected_deposit: 0,
                rate: 0,
                fuel_discount: 0,
                efs_fuel: 0,
                efs_fleet: 0,
                toll: 0,
                miles: 0,
                days_0: 0,
                days_1: 0,
                days_2: 0,
                days_3: 0,
                days_4: 0,
                days_5: 0,
                days_6: 0,
                total_day: 0,
                cash_adv: 0,
                expenses: 0,
                bonus: 0,
                violation_damage: 0,
                total_pay: 0,
                note: '',
                dpm: 0,
                previous_gross: 0,
                cash_adv_note: '-',
                exsp_note: '',
                bonus_note: '',
                violation_damage_note: '',
                paid_by_company: 0,
                salary: 0,
                fuel_efs_toll: 0,
                dispatch_28:
                  typeof element.calc !== 'number' ? 0 : 0.028 * element.calc,
                insurance: 0,
                lease: 0,
                other_exp: 0,
                profit_loss: 0,
              });
            }
            if (element.driverTwo !== null) {
              arr.push({
                rowId: a,
                number: a + 1,
                name:
                  element.driverTwo.firstName +
                  ' ' +
                  element.driverTwo.lastName,
                companyId: data.companyId,
                email: element.driverTwo.email,
                address: element.driverTwo.city,
                phone: element.driverTwo.phone,
                unit: element.number.split('-')[0],
                gross: typeof element.calc !== 'number' ? 0 : element.calc,
                dispatcher: '',
                collected_deposit: 0,
                rate: 0,
                fuel_discount: 0,
                efs_fuel: 0,
                efs_fleet: 0,
                toll: 0,
                miles: 0,
                days_0: 0,
                days_1: 0,
                days_2: 0,
                days_3: 0,
                days_4: 0,
                days_5: 0,
                days_6: 0,
                total_day: 0,
                cash_adv: 0,
                expenses: 0,
                bonus: 0,
                violation_damage: 0,
                total_pay: 0,
                note: '',
                dpm: 0,
                previous_gross: 0,
                cash_adv_note: '-',
                exsp_note: '',
                bonus_note: '',
                violation_damage_note: '',
                paid_by_company: 0,
                salary: 0,
                fuel_efs_toll: 0,
                dispatch_28:
                  typeof element.calc !== 'number' ? 0 : 0.028 * element.calc,
                insurance: 0,
                lease: 0,
                other_exp: 0,
                profit_loss: 0,
              });
            }
          }
        });
        // setRowData(arr);
        updateDataFirst(data.fileId, arr);
        setLoading(false);
        setCurrentFileName(fileName);
        toggleNewAccounting();
      })
      .catch((err) => {
        setLoading(false);

        try {
          if (err.response.status === 302) {
            toast.error(
              err.error_message ? err.error_message : 'Exist file name',
              {
                transition: Flip,
              }
            );
          }
        } catch (error) {
          console.log(error);
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageIndex(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setPage(0);
    // setRowsPerPage(+event.target.value);
  };

  const togglePdfViewer = () => {
    setShowPdfViewerModal(!showPdfViewerModal);
  };

  const updateData = () => {
    const data = {
      id: fileId,
      data: rowData,
    };
    accountingService
      .updateData(data)
      .then((res) => {
        if (res) {
          toast.success('Data successfuly edited', {
            transition: Flip,
          });
          setSaveAlert(false);
          getOneAccountingFileData(fileId);
        } else {
          toast.error(
            data.error_message ? data.error_message : 'Something went wrong',
            {
              transition: Flip,
            }
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const sentStatementFile = () => {
    const data = [];
    sendData.forEach((itemS, i) => {
      const element = sendData[i];
      const daysPeriod = [];
      [0, 1, 2, 3, 4, 5, 6].forEach((ol, k) => {
        const item = {
          date: days[k],
          daysWorked: element['days_' + k],
        };
        daysPeriod.push(item);
      });

      const item = {
        companyId: element.companyId,
        driverName: element.name,
        unit: element.unit,
        email: element.email,
        phone: element.phone,
        address: element.address,
        daysPeriodTable: daysPeriod,
        totalEarnings: '',
        expenses: element.expenses,
        cashAdvance: element.cash_adv,
        bonus: element.bonus,
        rate: element.rate,
        damageFeeViolation: element.violation_damage,
        miles: element.miles,
        totalPay: '',
      };
      data.push(item);
    });

    setOpenDrop(true);

    setSendAlert(false);
    accountingService.sendStatement(data).then((res) => {
      setPdfData(res);

      setOpenDrop(false);
      if (res) {
        toast.success('Document successfuly send', {
          transition: Flip,
        });
      } else {
        toast.error(
          data.error_message ? data.error_message : 'Something went wrong',
          {
            transition: Flip,
          }
        );
      }
    });
  };

  const handleCloseDrop = () => {
    setOpenDrop(false);
  };

  const handleSendAlert = () => {
    setSendAlert(!sendAlert);
  };

  useEffect(() => {
    setPageSize(10);
    getCompanies();
    setLoadingFiles(true);

    if (data) {
      const arr = [];
      let a = page * 10 + 1;
      data.map((element) => {
        arr.push({
          fileId: element.id,
          number: a,
          companyName: element.companyName,
          fileName: element.fileName,
          time:
            getDateString(element.startTime) +
            ' - ' +
            getDateString(element.endTime),
          createdTime: getDateString(element.createdTime),
          updatedTime: getDateString(element.updatedTime),
        });
        a += 1;
      });

      setSavedFiles(arr);
      setLoadingFiles(false);
    }
  }, [data]);

  const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;
  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openDrop}
        onClick={handleCloseDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SweetAlert
        title="Are you sure?"
        warning
        show={saveAlert}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, save it!"
        cancelBtnText="Cancel"
        onConfirm={() => {
          updateData();
        }}
        onCancel={() => setSaveAlert(false)}
      >
        {}
      </SweetAlert>
      <SweetAlert
        title="Are you sure?"
        warning
        show={sendAlert}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, send it!"
        cancelBtnText="Cancel"
        onConfirm={() => {
          sentStatementFile();
        }}
        onCancel={() => setSendAlert(false)}
      >
        {}
      </SweetAlert>
      <ModalWindow
        title="Document"
        modal={showPdfViewerModal}
        handleModal={togglePdfViewer}
        maxWidth=""
        body={
          <PdfViewer
            modal={showPdfViewerModal}
            toggle={togglePdfViewer}
            data={pdfData}
            handleSendAlert={handleSendAlert}
          />
        }
      />
      <NewAccounting
        modal={showNewAccountingModal}
        toggle={toggleNewAccounting}
        companies={companies}
        getData={getNewAccountingList}
      />

      <Card style={{ marginRight: 8 }}>
        <div>
          <CardHeader
            titleTypographyProps={{ variant: 'h2' }}
            action={
              <Button
                size="sm"
                color="success"
                className="d-flex align-items-center"
                type="button"
                onClick={() => {
                  toggleNewAccounting();
                }}
              >
                <Icon.PlusCircle size={20} /> New
              </Button>
            }
            title="Accounting"
          />
        </div>
        <CardBody>
          <Form>
            <Row>
              <Col md="4" sm="12">
                <FormGroup>
                  <Label for="search">Search</Label>
                  <Input
                    autoComplete="off"
                    type="text"
                    id="search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="2" sm="12">
                <FormGroup>
                  <Label for="from">Start</Label>
                  <Flatpickr
                    id="from"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                    value={startDate}
                    onChange={(val) => {
                      setStartDate(new Date(val).getTime());
                      setStart(new Date(val).getTime());
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="2" sm="12">
                <FormGroup>
                  <Label for="lastNameMulti">End</Label>
                  <Flatpickr
                    id="until"
                    className="form-control"
                    data-enable-time
                    options={{
                      dateFormat: 'Z',
                      altInput: true,
                      altFormat: 'm-d-Y H:i',
                    }}
                    value={endDate}
                    onChange={(val) => {
                      setEndDate(new Date(val).getTime());
                      setEnd(new Date(val).getTime());
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="12">
                <FormGroup>
                  <Label for="company">Company</Label>
                  <Select
                    id="company"
                    className="React"
                    classNamePrefix="select"
                    name="color"
                    options={companies}
                    isClearable={true}
                    onChange={(value) => {
                      setCompanyId(value.value);
                      setCurrentEmployerId(value.value);
                      // getAccountingList(
                      //     value.value
                      // );

                      // if (value !== null) {
                      //     this.setState({
                      //         company:
                      //             value.value,
                      //     });
                      // } else {
                      //     this.setState({
                      //         company: null,
                      //     });
                      // }
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#fe810b',
                        primary25: 'rgb(253, 179, 46)',
                        primary: 'rgb(253, 179, 46)',
                      },
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
          {loadingFiles ? (
            <Spin
              indicator={antIcon}
              style={{
                height: 'calc(100vh - 20rem)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          ) : (
            <Paper
              sx={{
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) =>
                        column.id !== 'fileId' ? (
                          <StyledTableCell
                            key={column.id}
                            align="left"
                            style={{
                              minWidth: column.minWidth,
                            }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ) : null
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {savedFiles
                      // .slice(
                      //     page * rowsPerPage,
                      //     page * rowsPerPage +
                      //         rowsPerPage
                      // )
                      .map((row, i) => {
                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={`${i + 1}`}
                          >
                            {columns.map((column) => {
                              if (column.id !== 'fileId') {
                                const value = row[column.id];
                                if (column.id === 'number') {
                                  return (
                                    <StyledTableCell
                                      key={column.id}
                                      align="left"
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      onDoubleClick={() => {
                                        // setSendData([]);
                                        // setCurrentFileName(row.fileName);
                                        // setFileId(row.fileId);
                                        // getOneAccountingFileData(
                                        //   row.fileId
                                        // );
                                        navigate(
                                          '/accounting/table/' + row.fileId
                                        );
                                      }}
                                    >
                                      {value}
                                    </StyledTableCell>
                                  );
                                }
                                if (column.id === '') {
                                  return (
                                    <StyledTableCell
                                      key={column.id}
                                      align="right"
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      // onDoubleClick={() => {
                                      //   setFileId(row.fileId);
                                      //   getOneAccountingFileData(
                                      //     row.fileId
                                      //   );
                                      // }}
                                    >
                                      <FilesMenu
                                        row={row}
                                        getAccountingFiles={getAccountingFiles}
                                        getDownloadFile={getDownloadFile}
                                      />
                                    </StyledTableCell>
                                  );
                                }
                                return (
                                  <StyledTableCell
                                    key={column.id}
                                    align="left"
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onDoubleClick={() => {
                                      // setSendData([]);
                                      // setCurrentFileName(row.fileName);
                                      // setFileId(row.fileId);
                                      // getOneAccountingFileData(
                                      //   row.fileId
                                      // );
                                      navigate(
                                        '/accounting/table/' + row.fileId
                                      );
                                    }}
                                  >
                                    {value}
                                  </StyledTableCell>
                                );
                              }
                            })}
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </CardBody>
      </Card>
    </>
  );
}
