import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Grid,
  InputAdornment,
  Card,
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { AnimatePresence } from 'framer-motion';
import SweetAlert from '../../../../../components/main/SweetAlert';
import FilterTools from '../../../../../components/main/FilteredBody/UnitStatus';
import AnimationDiv from '../../../../../components/main/Animations/Transition';

import { fetchData } from '../../../../../redux/modules/unit_status/actions';
import { useQuery } from '../../../useQuery';
import ModalWindow from '../../../../../components/main/Modals/FullScreen';
import AddEditUnitStatusModal from './AddEdit';

import unitService from '../../../../../services/units';
import { useStyles } from './style';

export default function UnitStatusModal({ modal, toggle, getList }) {
  const {
    query,
    search,
    deleted,
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    setSearch,
    setDeleted,
    setTripStatusId,
    setRefresh,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const classes = useStyles();

  const { data, loading, total } = useSelector(
    (state) => state.unitStatusReducer
  );
  console.log(data);
  const [unitStatuses, setUnitStatuses] = useState(data);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deletingId, setDeletingId] = useState();
  const [unitStatusId, setUnitStatusId] = useState();

  const [addEditUnitStatusModal, setAddEditUnitStatusModal] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    console.log(event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getUnitStatuses = () => {
    unitService
      .getUnitStatuses()
      .then((data) => {
        // setUnitStatuses(data.unit_statuses);
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   transition: Flip,
        // });
      });
  };

  const deleteUnitStatus = (id) => {
    unitService
      .deleteUnitStatus(id)
      .then((data) => {
        setRefresh('s');
        // setUnitStatuses(data.unit_statuses);
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   transition: Flip,
        // });
      });
  };

  useEffect(() => {
    getUnitStatuses();
  }, [modal]);

  return (
    <>
      <ModalWindow
        title="Add/Edit unit status modal"
        modal={addEditUnitStatusModal}
        handleModal={() => {
          setAddEditUnitStatusModal(false);
        }}
        maxWidth="md"
        body={
          <AddEditUnitStatusModal
            modal={addEditUnitStatusModal}
            id={unitStatusId}
            toggle={() => {
              setAddEditUnitStatusModal(false);
              getUnitStatuses();
            }}
          />
        }
      />
      <SweetAlert
        title="Are you sure?"
        warning
        show={deleteAlert}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        onConfirm={() => {
          // deleteDamageCategory(deletingId);
        }}
        onCancel={() => setDeleteAlert(false)}
      >
        {}
      </SweetAlert>
      <>
        <Container sx={{}}>
          <Card sx={{ padding: 2 }}>
            <Button
              color="success"
              variant="contained"
              className="d-flex align-items-center"
              onClick={() => {
                setUnitStatusId();
                setAddEditUnitStatusModal(true);
              }}
            >
              Add
            </Button>
            <div className="d-flex align-items-center mt-1 mb-1">
              <TextField
                fullWidth
                size="small"
                id="search"
                label="Search"
                variant="outlined"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              {/* <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={() => {
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton> */}
              {/* <h4 className="mx-1 mb-0 text-nowrap">&nbsp;</h4> */}
            </div>
            <div className="align-items-center mb-2">
              <AnimatePresence className={classes.filters}>
                <AnimationDiv>
                  <FilterTools deleted={deleted} setDeleted={setDeleted} />
                </AnimationDiv>
              </AnimatePresence>
            </div>
            <TableContainer sx={{ backgroundColor: 'white' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell key={`${'number'}`} align="left">
                      #
                    </TableCell>

                    <TableCell
                      key="name"
                      align="left"
                      style={{
                        minWidth: 100,
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      key="note"
                      align="left"
                      style={{
                        minWidth: 100,
                      }}
                    >
                      Note
                    </TableCell>
                    <TableCell
                      key="actions"
                      align="right"
                      // style={{ minWidth: 70 }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={`${i + 1}`}
                          onDoubleClick={() => {
                            console.log(row);
                            setUnitStatusId(row.id);
                            setAddEditUnitStatusModal(true);
                          }}
                        >
                          <TableCell key={`${'number_' + i}`} align="left">
                            {`${i + 1}`}
                          </TableCell>
                          <TableCell key={`${'name_' + i}`} align="left">
                            <Button
                              sx={{ backgroundColor: row.color }}
                              variant="contained"
                            >
                              {row.name}
                            </Button>
                          </TableCell>
                          <TableCell key={`${'note_' + i}`} align="left">
                            {row.note}
                          </TableCell>
                          <TableCell key={`${'delete_' + i}`} align="right">
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                deleteUnitStatus(row.id);
                                // setDeletingId(row.id);
                                // setDeleteAlert(true);
                              }}
                            >
                              {row.deleted === true ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </>
    </>
  );
}
