import auth from './actions/auth';
import lang from './actions/lang';
import trailerGeneral from './actions/trailer_general';
import trailerOptions from './actions/trailer_options';
import trailerRepairs from './actions/trailer_repairs';
import trailerHistory from './actions/trailer_history';
import trailerIdles from './actions/trailer_idles';
import trips from './actions/trips';
import loads from './actions/loads';
import drivers from './actions/drivers';
import driverNotifications from './actions/driver_notifications';
import units from './actions/units';
import unitStatus from './actions/unit_status';
import fuel from './actions/fuel';
import fuelBook from './actions/fuel_book';
import reports from './actions/reports';
import users from './actions/users';
import customers from './actions/customers';
import locations from './actions/locations';
import logs from './actions/logs';
import loadCalendar from './actions/load_calendar';
import accountingFiles from './actions/accounting_files';
import dashboard from './actions/dashboard';
import modals from './actions/modals';
import ownedCompany from './actions/owned_company';
import store from './actions/store';
import tasks from './actions/tasks';

export default {
  ...auth,
  ...lang,
  ...store,
  ...trips,
  ...loads,
  ...drivers,
  ...driverNotifications,
  ...units,
  ...unitStatus,
  ...fuel,
  ...fuelBook,
  ...reports,
  ...users,
  ...ownedCompany,
  ...customers,
  ...logs,
  ...locations,
  ...trailerGeneral,
  ...trailerOptions,
  ...trailerRepairs,
  ...trailerHistory,
  ...trailerIdles,
  ...loadCalendar,
  ...accountingFiles,
  ...dashboard,
  ...modals,
  ...tasks,
};
