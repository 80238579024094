import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Input,
} from 'reactstrap';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Helmet } from 'react-helmet';
import { Box, IconButton, TextField } from '@mui/material';
import * as Icon from 'react-feather';
import { toast, Flip } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { AnimatePresence } from 'framer-motion';
import FilterListIcon from '@mui/icons-material/FilterList';
import SweetAlert from '../../../../components/main/SweetAlert';
import FilterTools from '../../../../components/main/FilteredBody/Trips';
import AnimationDiv from '../../../../components/main/Animations/Transition';
import tripService from '../../../../services/trips';
import { fetchData } from '../../../../redux/modules/trips/actions';
import { useQuery } from '../../useQuery';
import Loading from '../../../../components/main/Loading';
import Table from '../../../../components/main/table';
import { setRefreshPage } from '../../../../redux/modules/store/actions';
import { tripsHeader } from '../../../../redux/constants/table/headers';
import { headerMaker } from '../../../../components/main/table/helper';
import { toolTipsUser, toolTipsAdmin } from './helper';
import { useStyles } from './style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default function Trips() {
  const {
    query,
    search,
    deleted,
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    setSearch,
    setDeleted,
    setTripStatusId,
    setRefresh,
    handleOnTableChange,
  } = useQuery({ fetchData });

  const classes = useStyles();
  const navigate = useNavigate();
  const [isFilterShow, setFilterIsShow] = useState(false);
  const [active, setActive] = useState(0);
  const [trips, setTrips] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [status, setStatus] = useState(
    localStorage.getItem('trip_tap') === null ||
      localStorage.getItem('trip_tap') === undefined
      ? '0'
      : localStorage.getItem('trip_tap')
  );
  const [deletingId, setDeletingId] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [page, setPage] = useState(null);
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();

  const headers = useMemo(() => headerMaker(tripsHeader), [tripsHeader]);

  const { data, loading, total } = useSelector((state) => state.tripsReducer);

  const { refreshPage, refreshPage1 } = useSelector(
    (state) => state.storeReducer
  );

  localStorage.setItem('total_data', total);
  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const createHeaders = (headerData) => {
    const cData = headerData.map((item) => ({
      item,
      // ref: useRef(),
    }));

    return cData;
  };

  const [columns, setColumns] = useState(createHeaders(headers));

  const handleModal = () => {
    setModal(!modal);
  };

  // state = {
  //   active: 0,
  //   trips: [],
  //   statuses: [],
  //   deletingId: null,
  //   deleteAlert: false,
  //   truckNumber: null,
  //   page: null,
  //   total: 1,
  //   loading: false,
  //   paginationPageSize: 100000,
  //   defaultColDef: {
  //     sortable: true,
  //     resizable: true,
  //     floatingFilter: true,
  //     filter: true,
  //     tooltipValueGetter: (params) => {
  //       return params.value;
  //     },
  //   },
  //   columnDefs: [
  //     {
  //       headerName: '#',
  //       field: 'index',
  //       maxWidth: 70,
  //       flex: 1,
  //       filter: false,
  //       cellStyle: function (params) {
  //         return {
  //           fontWeight: '500',
  //         };
  //       },
  //     },
  //     {
  //       headerName: 'Trip',
  //       field: 'id',
  //       minWidth: 140,
  //       filter: 'agTextColumnFilter',
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Unit',
  //       field: 'unitNumber',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 150,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Trailer',
  //       field: 'trailerNumber',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 150,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Load №',
  //       field: 'loadNumber',
  //       filter: 'agTextColumnFilter',
  //       minWidth: 150,
  //       flex: 1,
  //       cellRendererFramework: function (params) {
  //         return (
  //           <div className="drivers-col">
  //             <Link
  //               className="link-in-table"
  //               to={`/loads/edit/${params.data.loadId}`}
  //             >
  //               {params.value}
  //             </Link>
  //           </div>
  //         );
  //       },
  //     },
  //     {
  //       headerName: 'Pickup',
  //       field: 'pickup',
  //       maxWidth: 150,
  //       minWidth: 150,
  //       filter: false,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Delivery',
  //       field: 'delivery',
  //       maxWidth: 150,
  //       minWidth: 150,
  //       filter: false,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Driver',
  //       field: 'driver',
  //       minWidth: 300,
  //       maxWidth: 300,
  //       filter: false,
  //       flex: 1,
  //       cellRendererFramework: function (params) {
  //         return (
  //           <div className="drivers-col">
  //             <Link
  //               className="link-in-table"
  //               to={`/driver/view/${params.data.driverId}`}
  //             >
  //               {params.value}
  //             </Link>
  //             {params.data.teammateId !== null && (
  //               <Link
  //                 className="link-in-table"
  //                 to={`/driver/view/${params.data.teammateId}`}
  //               >
  //                 {params.data.teammateName}
  //               </Link>
  //             )}
  //           </div>
  //         );
  //       },
  //     },
  //     {
  //       headerName: 'Status',
  //       field: 'statusName',
  //       minWidth: 100,
  //       filter: false,
  //       flex: 1,
  //       cellStyle: function (params) {
  //         return {
  //           fontSize: '13px',
  //           color: params.data.statusColor ? 'white' : 'black',
  //           backgroundColor: params.data.statusColor
  //             ? params.data.statusColor
  //             : 'white',
  //           textAlign: 'center',
  //           textTransform: 'uppercase',
  //         };
  //       },
  //     },
  //     {
  //       headerName: 'Accessory Driver Pay',
  //       field: 'accessoryDriverPay',
  //       maxWidth: 170,
  //       filter: false,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'Driver Advance',
  //       field: 'driverAdvance',
  //       maxWidth: 140,
  //       filter: false,
  //       flex: 1,
  //     },
  //     {
  //       headerName: 'To',
  //       field: 'to',
  //       minWidth: 150,
  //       filter: false,
  //       flex: 1,
  //       tooltip: (params) => {
  //         return params.value;
  //       },
  //     },
  //     {
  //       minWidth: 125,
  //       headerName: '',
  //       field: 'actions',
  //       sortable: false,
  //       editable: false,
  //       suppressMenu: false,
  //       filter: false,
  //       flex: 1,
  //       cellRendererFramework: (params) => {
  //         return (
  //           <div>
  //             <Link to={`/trips/view/${params.data.id}`}>
  //               <Icon.Eye className="icon-button" size={20} color="darkgray" />
  //             </Link>
  //             <Link to={`/trips/edit/${params.data.id}`}>
  //               <Icon.Edit
  //                 className="icon-button ml-1"
  //                 size={20}
  //                 color="darkgray"
  //               />
  //             </Link>
  //             {this.props.userRole === 'admin' && (
  //               <Icon.Delete
  //                 onClick={() => this.nominateToDelete(params.data.id)}
  //                 className="icon-button ml-1"
  //                 size={20}
  //                 color="darkgray"
  //               />
  //             )}
  //           </div>
  //         );
  //       },
  //     },
  //   ],
  // };

  // const componentDidUpdate = (prevProps, prevState)  {
  //   if (
  //     this.state.page !== prevState.page ||
  //     this.state.truckNumber !== prevState.truckNumber
  //   ) {
  //     if (
  //       this.state.truckNumber !== prevState.truckNumber &&
  //       this.state.page !== 0
  //     ) {
  //       this.setState({ page: 0 });
  //       return;
  //     }
  //     this.setState({
  //       loading: true,
  //     });
  //     let truckNumber = this.state.truckNumber;
  //     tripService
  //       .getAll(
  //         `${
  //           this.state.active && `status_id=${this.state.active}`
  //         }&sort=id,DESC&size=100&page=${this.state.page}${
  //           truckNumber ? `&truck_number=${truckNumber}` : ''
  //         }`
  //       )
  //       .then((data) => {
  //         if (truckNumber !== this.state.truckNumber) return;
  //         let dataToShow = [];
  //         data.content.forEach((el, i) => {
  //           let elToShow = {
  //             index: i + 1 + this.state.page * 100,
  //             id: el.id,
  //             loadNumber: el.loadNumber,
  //             loadId: el.loadId,
  //             driverId: el.driverId,
  //             driver: el.driverName,
  //             accessoryDriverPay: el.accessoryDriverPay,
  //             primaryPhone: el.phone,
  //             driverAdvance: el.driverAdvance,
  //             to: el.to,
  //             statusName: el.statusName,
  //             statusColor: el.statusColor,
  //             hiredOn: el.hireDate,
  //             delivery: el.deliveryDateFormatted,
  //             pickup: el.pickDateFormatted,
  //             teammateName: el.teammateName,
  //             teammateId: el.teammateId,
  //             unitNumber: el.unitNumber,
  //             trailerNumber: el.trailerNumber
  //               ? el.trailerNumber
  //               : el.unknownTrailer,
  //           };
  //           dataToShow.push(elToShow);
  //         });
  //         this.setState({
  //           trips: dataToShow,
  //           loading: false,
  //           total: data.total_elements,
  //         });
  //       })
  //       .catch((err) => {
  //         toast.error(err.response.data.message, {
  //           transition: Flip,
  //         });
  //       });
  //   }
  // }
  const componentDidMount = () => {
    let dispatcher = null;
    const userRole = '';
    if (userRole === 'dispatcher') {
      const token = localStorage.getItem(
        localStorage.getItem('current_account') + '_access_token'
      );
      if (token) {
        const decodedToken = jwtDecode(token.replace('Bearer ', ''));
        dispatcher = decodedToken.userId;
      }
    }
    // setLoading(true);

    tripService
      .getAll(
        `sort=id,DESC&size=100${
          dispatcher ? `&dispatcherId=${dispatcher}` : ''
        }`
      )
      .then((data) => {
        const dataToShow = [];
        data.content.forEach((el, i) => {
          const elToShow = {
            index: i + 1 + page * 100,
            id: el.id,
            loadNumber: el.loadNumber,
            loadId: el.loadId,
            driverId: el.driverId,
            driver: el.driverName,
            accessoryDriverPay: el.accessoryDriverPay,
            primaryPhone: el.phone,
            driverAdvance: el.driverAdvance,
            to: el.to,
            statusName: el.statusName,
            statusColor: el.statusColor,
            hiredOn: el.hireDate,
            delivery: el.deliveryDateFormatted,
            pickup: el.pickDateFormatted,
            teammateName: el.teammateName,
            teammateId: el.teammateId,
            unitNumber: el.unitNumber,
            trailerNumber: el.trailerNumber
              ? el.trailerNumber
              : el.unknownTrailer,
          };
          dataToShow.push(elToShow);
        });
        setTrips(dataToShow);
        // setLoading(false);
        // setTotal(data.total_elements);
        setStatuses(data.trip_statuses);
        // this.setState({
        //   trips: dataToShow,
        //   loading: false,
        //   total: data.total_elements,
        //   statuses: data.trip_statuses,
        // });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const toggle = (tab) => {
    setTripStatusId(tab);
    setActive(tab);
  };

  const nominateToDelete = (id) => {
    setDeletingId(id);
    setDeleteAlert(true);
  };

  const deleteTrip = () => {
    tripService
      .delete(deletingId)
      .then((res) => {
        setRefresh(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });

    setDeletingId(null);
    setDeleteAlert(false);
  };

  const doubleClick = (id) => {
    navigate(`/trips/view/${id}`);
  };

  useEffect(() => {
    setStatuses(data.trip_statuses ? data.trip_statuses : []);
  }, [data]);

  useEffect(() => {
    localStorage.setItem('trip_tab', '0');
  }, []);

  useEffect(() => {
    if (refreshPage !== undefined) {
      dispatch(setRefreshPage());
      setRefresh(true);

      toast.success('Status successfully edit', {
        transition: Flip,
      });
    }
  }, [refreshPage]);

  return (
    <>
      <Helmet>
        <title> Trips </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#000',
          height: 'calc(100vh - 4.0rem)',
          py: 1,
        }}
      >
        <Loading loading={loading} />
        <SweetAlert
          title="Are you sure?"
          warning
          show={deleteAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="Cancel"
          onConfirm={() => {
            deleteTrip(deletingId);
          }}
          onCancel={() => setDeleteAlert(false)}
        >
          {}
        </SweetAlert>
        <Card
          className="overflow-hidden agGrid-card"
          style={{ marginRight: 8 }}
        >
          <div className="d-flex justify-content-between align-items-start mt-2 mx-2 my-1 ">
            <div
              style={{
                paddingRight: '15px',
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  // display: 'flex',
                  // flexDirection: 'row',
                  // gap: '10px',
                  justifyContent: 'flex-start',
                }}
              >
                <FormControl sx={{ width: 200 }} size="small">
                  <InputLabel id="simple-select-label9">
                    Select status
                  </InputLabel>
                  <Select
                    labelId="simple-select-label9"
                    id="simple-select"
                    value={status}
                    label="Select status"
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      const { value } = e.target;
                      console.log(value);

                      if (value !== undefined && value === '0') {
                        localStorage.setItem('trip_tab', '0');
                        setStatus('0');
                        toggle(0);
                      } else {
                        localStorage.setItem('trip_tab', value);
                        setStatus(value);
                        toggle(value);
                      }
                    }}
                  >
                    {statuses.length > 1 ? (
                      <MenuItem key="empty" value="0">
                        All
                      </MenuItem>
                    ) : null}
                    {statuses.map((item, i) => {
                      return (
                        <MenuItem key={`${i + 1}`} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            {/* <div className="d-flex justify-content-between align-items-start">
              <Nav pills className="dashboard-tabs">
                <NavItem>
                  <NavLink
                    style={{
                      height: 37,
                      backgroundColor:
                        localStorage.getItem('trip_tab') === 'All'
                          ? '#00FF7F'
                          : 'white',
                    }}
                    className="d-flex align-items-center"
                    onClick={() => {
                      localStorage.setItem('trip_tab', 'All');
                      toggle(0);
                    }}
                  >
                    <h4 className="mb-0 ml-1 mr-1">All</h4>
                  </NavLink>
                </NavItem>
                {statuses.map((item) => (
                  <NavItem key={item.id}>
                    <NavLink
                      style={{
                        height: 37,
                        backgroundColor:
                          localStorage.getItem('trip_tab') === item.name
                            ? '#00FF7F'
                            : 'white',
                      }}
                      className="d-flex align-items-center"
                      onClick={() => {
                        localStorage.setItem('trip_tab', item.name);
                        toggle(item.id);
                      }}
                    >
                      <h4 className="mb-0 ml-1 mr-1">{item.name}</h4>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div> */}
            <Link
              to="/trips/new"
              className="d-flex align-items-start text-white"
            >
              <Button
                size="sm"
                color="success"
                className="d-flex align-items-center"
                type="button"
              >
                <Icon.Plus size={20} /> Add new trip
              </Button>
            </Link>
          </div>
          <div className="d-flex align-items-center mx-2">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search"
              variant="outlined"
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={() => {
                setFilterIsShow(!isFilterShow);
              }}
            >
              <FilterListIcon />
            </IconButton>
            {/* <h4 className="mx-1 mb-0 text-nowrap">&nbsp;</h4> */}
          </div>
          <div className={isFilterShow ? 'mx-2' : ''}>
            <AnimatePresence className={classes.filters}>
              {isFilterShow ? (
                <AnimationDiv>
                  <FilterTools deleted={deleted} setDeleted={setDeleted} />
                </AnimationDiv>
              ) : null}
            </AnimatePresence>
          </div>
          <CardBody>
            <div style={{ paddingBottom: 10 }}>
              <Table
                data={data}
                headers={headers}
                toolTips={
                  decodedToken.role === 'ROLE_ADMIN' ||
                  decodedToken.role === 'SUPER_ADMIN'
                    ? toolTipsAdmin
                    : toolTipsUser
                }
                onChange={handleOnTableChange}
                modal={handleModal}
                deleteModal={nominateToDelete}
                doubleClick={doubleClick}
                columns={columns}
                setColumns={setColumns}
                // hidePagination={1}
              />
            </div>
          </CardBody>
        </Card>
      </Box>
    </>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     token: state.auth.login.token,
//     userRole: state.auth.login.userRole,
//   };
// };
// export default connect(mapStateToProps)(Trips);
