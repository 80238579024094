import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Media,
  Row,
  Col,
  Button,
  CustomInput,
} from 'reactstrap';
import { Grid } from '@mui/material';
import { Edit } from 'react-feather';
import jwtDecode from 'jwt-decode';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import '../../../../../assets/scss/pages/users.scss';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import driverService from '../../../../../services/drivers';
import Loading from '../../../../../components/main/Loading';

export default function ViewDriverModal({ id }) {
  const [status, setStatus] = useState('');
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [data, setData] = useState({});
  const [driverStatuses, setDriverStatuses] = useState([]);
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token.replace('Bearer ', ''));

  const setterStatus = (ids) => {
    driverService
      .editStatus(id, ids)
      .then((res) => {
        if (res) {
          toast.success('Status successfuly changed', {
            transition: Flip,
          });
          setStatus(ids);
        } else {
          toast.error('Something went wrong', { transition: Flip });
          // res.text();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const dateFormat = (dateValue) => {
    const date = new Date(dateValue + 1 * 24 * 60 * 60 * 1000);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const dateS = day.toString().padStart(2, '0');

    const monthS = month.toString().padStart(2, '0');
    return `${year}-${monthS}-${dateS}`;
  };

  const componentDidMount = () => {
    setLoading(true);
    driverService
      .getContext()
      .then((data) => {
        setDriverStatuses(data.driver_statuses);
        setPaymentTypes(data.payment_types);

        driverService
          .getOne(id)
          .then((data) => {
            setData(data);
            setLoading(false);
            setStatus(data.driverStatusId);
          })
          .catch((err) => {
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <Grid container spacing={2}>
        <Col sm="12">
          <Card style={{ marginRight: 8 }}>
            <CardHeader>
              <CardTitle>Driver</CardTitle>
            </CardHeader>
            <CardBody>
              <Row className="mx-0" col="12">
                <Col className="pl-0" sm="12">
                  <Media className="d-sm-flex d-block">
                    <Media body>
                      <Row>
                        <Col sm="9" md="6" lg="6">
                          <div className="users-page-view-table">
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                Name
                              </div>
                              <div>{data.firstName + ' ' + data.lastName}</div>
                            </div>
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                Email
                              </div>
                              <div className="text-truncate">
                                <span>{data.email}</span>
                              </div>
                            </div>
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                Phone number
                              </div>
                              <div>{data.phone}</div>
                            </div>
                          </div>
                        </Col>
                        <Col md="12" lg="6">
                          <div className="users-page-view-table">
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                City
                              </div>
                              <div>{data.city}</div>
                            </div>
                            <div className="d-flex user-info">
                              <div className="user-info-title font-weight-bold">
                                Street
                              </div>
                              <div>
                                <span>{data.street}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Media>
                  </Media>
                </Col>
                <Col className="mt-1 pl-0 d-flex" sm="12">
                  {decodedToken.role === 'ROLE_ADMIN' ||
                  decodedToken.role === 'SUPER_ADMIN' ? (
                    <Link to={`/drivers/edit/${id}`}>
                      <Button
                        className="mr-1"
                        color="primary"
                        type="button"
                        outline
                      >
                        <Edit size={15} />
                        <span className="align-middle ml-50">Edit</span>
                      </Button>
                    </Link>
                  ) : null}

                  {decodedToken.role === 'ROLE_ADMIN' ||
                  decodedToken.role === 'SUPER_ADMIN' ? (
                    <CustomInput
                      style={{
                        width: 230,
                        height: 41,
                      }}
                      type="select"
                      name="select"
                      id="driver_status"
                      onChange={(e) => setterStatus(e.target.value)}
                      value={status}
                    >
                      {driverStatuses.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </CustomInput>
                  ) : null}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="6">
          <Card>
            <CardHeader>
              <CardTitle>Information</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="users-page-view-table">
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    Alternate Phone Number
                  </div>
                  <div>{data.alternatePhone}</div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    Hire Date
                  </div>
                  <div className="text-truncate">
                    <span>{data.hireDateFormatted}</span>
                  </div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    License Number
                  </div>
                  <div className="text-truncate">
                    <span>{data.licenseNumber}</span>
                  </div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    Medical Card Renewal
                  </div>
                  <div className="text-truncate">
                    <span>
                      {data.medicalCardRenewal !== null
                        ? dateFormat(
                            new Date(data.medicalCardRenewal).getTime()
                          )
                        : null}
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="6">
          <Card>
            <CardHeader>
              <CardTitle>Files</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="users-page-view-table">
                <div className="d-flex user-info">
                  {data.licenseFileId && (
                    <Button
                      style={{ width: 225 }}
                      className="d-flex align-items-center"
                      type="button"
                      // href={`${process.env.REACT_APP_BASE_URL}/file/${data.licenseFileId}`}
                      onClick={() => {
                        fetch(
                          `${process.env.REACT_APP_BASE_URL}/file/${data.licenseFileId}`,
                          {
                            method: 'GET',
                            headers: {
                              Authorization:
                                'Bearer ' +
                                localStorage.getItem(
                                  localStorage.getItem('current_account') +
                                    '_access_token'
                                ),
                            },
                          }
                        ).then((response) => {
                          response.blob().then((blob) => {
                            const spl = response.headers
                              .get('content-disposition')
                              .split(';');

                            const filename = spl[1]
                              .split('=')[1]
                              .replace('"', '');
                            const fileName2 = filename.replace('"', '');

                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            const alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.target = '_blank';
                            alink.download = fileName2;
                            alink.click();
                          });
                        });
                      }}
                    >
                      License
                    </Button>
                  )}
                  {data.medicalCardFileId && (
                    <Button
                      style={{ width: 225 }}
                      className="d-flex align-items-center"
                      type="button"
                      // href={`${process.env.REACT_APP_BASE_URL}/file/${data.medicalCardFileId}`}
                      onClick={() => {
                        fetch(
                          `${process.env.REACT_APP_BASE_URL}/file/${data.medicalCardFileId}`,
                          {
                            method: 'GET',
                            headers: {
                              Authorization:
                                'Bearer ' +
                                localStorage.getItem(
                                  localStorage.getItem('current_account') +
                                    '_access_token'
                                ),
                            },
                          }
                        ).then((response) => {
                          response.blob().then((blob) => {
                            const spl = response.headers
                              .get('content-disposition')
                              .split(';');

                            const filename = spl[1]
                              .split('=')[1]
                              .replace('"', '');
                            const fileName2 = filename.replace('"', '');

                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            const alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.target = '_blank';
                            alink.download = fileName2;
                            alink.click();
                          });
                        });
                      }}
                    >
                      Medical Cart
                    </Button>
                  )}
                  {data.socialSecurityFileId && (
                    <Button
                      style={{ width: 225 }}
                      className="d-flex align-items-center"
                      type="button"
                      // href={`${process.env.REACT_APP_BASE_URL}/file/${data.socialSecurityFileId}`}
                      onClick={() => {
                        fetch(
                          `${process.env.REACT_APP_BASE_URL}/file/${data.socialSecurityFileId}`,
                          {
                            method: 'GET',
                            headers: {
                              Authorization:
                                'Bearer ' +
                                localStorage.getItem(
                                  localStorage.getItem('current_account') +
                                    '_access_token'
                                ),
                            },
                          }
                        ).then((response) => {
                          response.blob().then((blob) => {
                            const spl = response.headers
                              .get('content-disposition')
                              .split(';');

                            const filename = spl[1]
                              .split('=')[1]
                              .replace('"', '');
                            const fileName2 = filename.replace('"', '');

                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            const alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.target = '_blank';
                            alink.download = fileName2;
                            alink.click();
                          });
                        });
                      }}
                    >
                      Social Security Number
                    </Button>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Card>
            <CardHeader>
              <CardTitle>Files</CardTitle>
            </CardHeader>
            <CardBody>
              {/* <div className="users-page-view-table">
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    Default Payment Type
                  </div>
                  <div className="text-truncate">
                    <span>
                      {data.defaultPaymentTypeId &&
                        paymentTypes.find(
                          (item) => item.id === data.defaultPaymentTypeId
                        ).name}
                    </span>
                  </div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    License Expiration
                  </div>
                  <div className="text-truncate">
                    <span>
                      {dateFormat(new Date(data.licenseExpiration).getTime())}
                    </span>
                  </div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    Medical Card Expiration
                  </div>
                  <div className="text-truncate">
                    <span>{dateFormat(new Date(data.licenseExpiration).getTime())}</span>
                  </div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    Hire Date
                  </div>
                  <div className="text-truncate">
                    <span>1 January 2021</span>
                  </div>
                </div>
                <div className="d-flex user-info">
                  <div className="user-info-title font-weight-bold">
                    Termination Date
                  </div>
                  <div className="text-truncate">
                    <span>5 January 2021</span>
                  </div>
                </div>
              </div> */}
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
