import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navbar from '../../NavBars/DashboardNavbar';
import { Content, Root } from './style';
import Sidebar from '../../Sidebar';
import ModalWindow from '../../Modals';

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const matches = useMediaQuery('(min-width:1200px)');
  const [expand, setExpand] = useState(false);
  const location = useLocation();

  const modal = useSelector((state) => state.storeReducer.modal);

  const handleExpand = () => {
    setExpand(!expand);
  };

  const reSize = () => {
    if (!expand && !matches) {
      return 1;
    }
    if (expand && matches) {
      return 182;
    }
    if (expand && !matches) {
      return 1;
    }
    return 50;
  };

  const checkPath = () => {
    if (location.pathname !== '/drivers/add/newForm') {
      return true;
    }
    return false;
  };

  return (
    <>
      {/* <ModalWindow
        title="Login"
        modal={modal}
        handleModal={() => {}}
        maxWidth="sm"
        bodyHeight="350px"
        body={<></>}
      /> */}
      {/* <Root> */}
      {checkPath() ? (
        <>
          <Navbar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <Sidebar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
            expand={expand}
            handleExpand={handleExpand}
          />
        </>
      ) : null}
      <div
        style={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          paddingTop: checkPath() ? 50 : 0,
          paddingLeft: checkPath() ? reSize() : 0,
          position: 'relative',
          top: 0,
          left: 0,
          backgroundColor: '#000',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: '1 1 auto',
            backgroundColor: '#000',
            overflow: 'hidden',
            // height: 'calc(100% + 100px)',
            paddingLeft: 5,
            paddingTop: 20,
            paddingBottom: 2,
          }}
        >
          <Content>
            <Outlet />
          </Content>
        </div>
      </div>
      {/* </Root> */}
    </>
  );
};

export default DashboardLayout;
