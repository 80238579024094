import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ColorPicker, createColor } from 'material-ui-color';
import { toast, Flip } from 'react-toastify';
import { Form, FormGroup, Input } from 'reactstrap';
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Grid,
  InputAdornment,
  Card,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { inputLabelClasses } from '@mui/material/InputLabel';
import unitService from '../../../../../../services/units';

const useStyles = makeStyles({
  root: {
    border: '1px solid red',
  },
});

export default function AddEditUnitStatusModal({ modal, id, toggle, getList }) {
  const classes = useStyles();
  const theme = useTheme();
  const [color, setColor] = useState(createColor('#000'));

  const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      note: '',
      color: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (id) {
        const data = {
          id,
          name: values.name,
          note: values.note,
          color: values.color,
          deleted: values.deleted,
        };
        unitService
          .updateUnitStatus(data)
          .then((res) => {
            console.log(res);
            toggle();
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      } else {
        const data = {
          name: values.name,
          note: values.note,
          color: values.color,
          deleted: values.deleted,
        };
        unitService
          .createUnitStatus(data)
          .then((res) => {
            console.log(res);
            toggle();
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message, {
              transition: Flip,
            });
          });
      }
    },
  });

  const handleColorChange = (value) => {
    console.log(value);
    setColor(value);
    formik.setFieldValue('color', '#' + value.hex);
  };

  useEffect(() => {}, [modal]);

  useEffect(() => {
    if (id) {
      console.log(id);
      unitService
        .getOneUnitStatus(id)
        .then((res) => {
          setColor(createColor(res.color ? res.color : '#000'));
          formik.setValues({
            ...formik.values,
            name: res.name,
            note: res.note,
            color: res.color,
            deleted: res.deleted,
          });
        })
        .catch((err) => {});
    }
  }, [id]);

  return (
    <>
      <Container sx={{}}>
        <Card sx={{ padding: 2 }}>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container my={2}>
              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      '&:focus': {
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                      },
                    },
                  }}
                  // InputLabelProps={{
                  //   sx: {
                  //     color: 'orange',
                  //     [`&.${inputLabelClasses.shrink}`]: {
                  //       color: 'red',
                  //     },
                  //   },
                  // }}
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  size="small"
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label="Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.name}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} md={12} sm={12} my={2}>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      '&:focus': {
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                      },
                    },
                  }}
                  // InputLabelProps={{
                  //   sx: {
                  //     color: 'orange',
                  //     [`&.${inputLabelClasses.shrink}`]: {
                  //       color: 'red',
                  //     },
                  //   },
                  // }}
                  error={Boolean(formik.touched.note && formik.errors.note)}
                  size="small"
                  fullWidth
                  helperText={formik.touched.note && formik.errors.note}
                  label="Note"
                  name="note"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  multiline
                  rows={8}
                  value={formik.values.note}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Button
                sx={{ backgroundColor: '#' + color.hex }}
                variant="contained"
              >
                {formik.values.name}
              </Button>
              <Grid item xs={12} md={12} sm={12} my={2}>
                <ColorPicker
                  value={color}
                  classes={classes}
                  onChange={handleColorChange}
                />
              </Grid>
            </Grid>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={() => {
                // addDamageCategory();
              }}
            >
              Save
            </Button>
          </form>
        </Card>
      </Container>
    </>
  );
}
