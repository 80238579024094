export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    name: item.name,
    color: item.color,
    note: item.note,
    deleted: item.deleted,
    data: item,
  }));
  return { total: dataResponse.totalElements, data: filtered };
}
