export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.data.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    // state: stateId[0] === undefined ? '' : stateId[0].name,
    name: item.companyName,
    city: item.city,
    abbreviation: item.abbreviation,
    deleted: item.deleted,
  }));
  return { total: dataResponse.total, data: filtered };
}
