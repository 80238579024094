import { service } from '.';

export default {
  getAll: (query) =>
    service.get(`/accounting?${query}`, { responseType: 'blob' }),
  getAllInfo: (query) => service.get(`/accounting/info?${query}`),
  getAllList: (query) => service.get(`/accounting/list?${query}`),
  getAccountingFiles: (query) => service.get(`/accounting/files?${query}`),
  getOneAccountingFile: (id) =>
    service.get(`/accounting/files/get_by_id/${id}`),
  getDownloadAccountingFile: (id) =>
    service.get(`/accounting/download_files/get_by_id/${id}`),
  updateFileName: (data) =>
    service.put(`/accounting/files/edit/filename`, data),
  updateData: (data) => service.put(`/accounting/files/edit/data`, data),
  getStatement: (data) => service.post(`/accounting/files/get_statement`, data),
  sendStatement: (data) =>
    service.post(`/accounting/files/send_statement`, data),
};
