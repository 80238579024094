export function dataSelector(dataResponse, page, size) {
  const filtered = dataResponse.content.map((item, i) => ({
    index: i + 1 + page * size,
    id: item.id,
    name: item.lastName + ' ' + item.firstName,
    address: item.address + ', ' + item.street,
    primaryPhone: item.phone,
    alternatePhone: item.alternatePhone,
    paymentType: item.paymentType,
    status: item.driverStatusId,
    hiredOn: item.hireDateFormatted,
    licenseExp: item.licenseExpirationFormatted,
    medicalCardExp: item.medicalCardRenewalFormatted,
    driverStatusColor: item.driverStatusColor,
    deleted: item.deleted,
    data: item,
    dataDriver: item,
  }));
  return { total: dataResponse.totalElements, data: filtered };
}
